@use 'sass:list';

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        /**
         * Provide responsive border widths
         * Usage: border-0, border-md-1, border-bottom-md-0
         */
        @each $width in $border-widths {
            .border#{$infix}-#{$width} {
                border-width: $width * 1px !important;
                border-style: solid !important;
            }

            @each $position in $border-positions {
                .border-#{$position}#{$infix}-#{$width} {
                    border-#{$position}-width: $width * 1px !important;
                    border-#{$position}-style: solid !important;
                }
            }
        }

        /**
         * Provide border radius classes for every breakpoint.
         * Usage: rounded-sm, rounded-lg-md
         */
        @each $key, $value in $border-radiuses {
            .rounded#{$infix}-#{$key} {
                border-radius: $value !important;
            }
        }

        /**
         * Provide all theme colors as border colors for every breakpoint.
         # Usage: border-promo, border-lg-neutral-white
         */
        @each $key, $value in $theme-colors {
            .border#{$infix}-#{$key} {
                border-color: $value !important;
            }
        }

        /**
         * Provide all gray colors as border colors for every breakpoint.
         * Usage: border-gray-100, border-md-gray-500
         */
        @each $key, $value in $grays {
            .border#{$infix}-gray-#{$key} {
                border-color: $value !important;
            }
        }
    }
}

.border-bottom-dashed {
    border-bottom-style: dashed;
}

.border-bottom-dotted {
    border-bottom-style: dotted;
}
