/**
 * Make all theme colors and gray tints values available as text and background classes, responsively.
 *
 * Usage: text-neutral-white or bg-gray-md-100
 */
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $color, $value in $theme-colors {
            .text#{$infix}-#{$color} {
                color: $value !important;
            }

            .text#{$infix}-hover-#{$color}:hover {
                color: $value !important;
            }

            .bg#{$infix}-#{$color} {
                background-color: $value !important;
            }
        }

        @each $color, $value in $grays {
            .text#{$infix}-gray-#{$color} {
                color: $value !important;
            }

            .text#{$infix}-hover-gray-#{$color}:hover {
                color: $value !important;
            }

            .bg#{$infix}-gray-#{$color} {
                background-color: $value !important;
            }
        }
    }
}
