@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        /**
         * Provide responsive flex settings
         * Usage: flex-grow-md-0, flex-grow-0
         */

        .flex-grow#{$infix}-0 {
            flex-grow: 0 !important;
        }
    }
}
