/* Components and children
----------------------------------------------------------- */
.price-superscript {
    line-height: 1;
}

// Large
.price-superscript-lg {
    .price-superscript-big {
        font-size: font-size(40);

        @include media-breakpoint-up('md') {
            font-size: font-size(54);
        }
    }

    .price-superscript-comma {
        letter-spacing: -4px;
        text-indent: -4px;
        font-size: font-size(40);

        @include media-breakpoint-up('md') {
            font-size: font-size(54);
            letter-spacing: -6px;
        }
    }

    .price-superscript-little {
        padding-top: 3px;
        font-size: font-size(18);

        @include media-breakpoint-up('md') {
            padding-top: 5px;
            font-size: font-size(30);
        }

        @include media-breakpoint-up('lg') {
            padding-top: 6px;
        }
    }
}

// Medium
.price-superscript-md {
    .price-superscript-big {
        font-size: font-size(24);
        letter-spacing: -2px;

        @include media-breakpoint-up('md') {
            font-size: font-size(36);
        }
    }

    .price-superscript-comma {
        letter-spacing: -2px;
        text-indent: -2px;
        font-size: font-size(24);

        @include media-breakpoint-up('md') {
            font-size: font-size(36);
            letter-spacing: -4px;
        }
    }

    .price-superscript-little {
        font-size: font-size(12);
        padding-top: 3px;

        @include media-breakpoint-up('md') {
            font-size: font-size(20);
            padding-top: 3px;
        }
    }
}

/* Modifiers
----------------------------------------------------------- */
.price-lg {
    font-size: font-size(20);

    @include media-breakpoint-up('md') {
        font-size: font-size(26);
    }
}

.price-md {
    font-size: font-size(16);

    @include media-breakpoint-up('md') {
        font-size: font-size(20);
    }
}

.price-sm {
    font-size: font-size(12);

    @include media-breakpoint-up('md') {
        font-size: font-size(14);
    }
}
