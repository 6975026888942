@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $font-size in $font-sizes {
            .font-size#{$infix}-#{$font-size} {
                font-size: $font-size * 1px;
            }
        }

        @each $line-height in $font-sizes {
            .line-height#{$infix}-#{$line-height} {
                line-height: $line-height * 1px;
            }
        }

        @for $i from 1 through 10 {
            .max-lines#{$infix}-#{$i} {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: $i; /* number of lines to show */
                line-clamp: $i; /* number of lines to show */
                -webkit-box-orient: vertical;
            }
        }

        .line-height#{$infix}-1 {
            line-height: 1;
        }

        .line-height#{$infix}-span {
            line-height: 1.15;
        }

        .font-weight#{$infix}-bold {
            font-family: $font-family-bold;
            font-weight: 400;
        }

        .font-weight#{$infix}-normal {
            font-family: $font-family-sans-serif;
            font-weight: 400;
        }
    }
}

@for $i from 1 through 10 {
    .max-lines-#{$i} {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: $i; /* number of lines to show */
        line-clamp: $i; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
}

.line-height-1 {
    line-height: 1;
}

.line-height-span {
    line-height: 1.15;
}

.line-height-default {
    line-height: $line-height-base !important;
}

.line-height-normal {
    line-height: 1.25;
}

.strike {
    text-decoration: line-through;
}

.text-number {
    font-feature-settings: 'tnum' on, 'lnum' on;
}

.lead {
    @include media-breakpoint-up('lg') {
        font-size: font-size(24);
    }
}

a.unstyled {
    color: unset;
    border-bottom: none;
    font-family: $font-family-sans-serif;
    font-weight: 400;

    &:hover,
    &:focus {
        color: $purple;
    }
}
