.opening-hours {
    tr {
        &:not(:first-child) {
            border-top: 1px solid $neutral-a10;
        }

        td {
            padding: spacer(2) spacer(0);
            &:nth-child(2n) {
                margin-left: spacer(4);
            }
        }
    }
}
