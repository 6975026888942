/* Components and children
----------------------------------------------------------- */
// override
.alert {
    @include media-breakpoint-down('sm') {
        font-size: font-size(14);
    }
}

/* Modifiers
----------------------------------------------------------- */
// override
@each $color, $value in $theme-colors {
    .alert-#{$color} {
        border-color: rgba($value, 0.5);
        color: #{$value};
        background-color: rgba($value, 0.05);
    }
}
