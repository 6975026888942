.category-active {
    position: relative;
    &::before {
        content: '';
        height: 2rem;
        border-left: 4px solid $promo;
        position: absolute;
        left: -18px;
        top: 0.3rem;
        border-radius: 2px;
    }
}
