.accessory-variant-dropdown {
    .dropdown-toggle {
        white-space: normal;
    }

    .dropdown-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.menu-dropdown {
    top: 100%;
    z-index: 1000;
    box-shadow: '0px 6px 20px rgba(0, 0, 0, 0.1)';
}
