/* Components and children
----------------------------------------------------------- */
.logo-loader {
    position: relative;
    display: block;
    margin-bottom: 0;
    height: $logo-vertical-height-small;
    width: $logo-vertical-width-small;
    text-indent: -99999px;

    background-image: url('/assets/images/svg/goed-logo.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    animation: heartbeat 2s infinite ease;
}

@keyframes heartbeat {
    0% {
        transform: scale(0.85);
    }
    30% {
        transform: scale(0.85);
    }
    37% {
        transform: scale(1);
    }
    44% {
        transform: scale(0.85);
    }
    51% {
        transform: scale(1);
    }
    58% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(0.85);
    }
}

/* Modifiers
----------------------------------------------------------- */
