/* Components and children
----------------------------------------------------------- */
.sprite {
    transition: transition-duration(1);
}

.sprite-hover {
    opacity: 0.4;

    transition: transition-duration(1);
}

.sprite-hover-master:hover {
    .sprite-hover {
        opacity: 1;
    }
}

/* Modifiers
----------------------------------------------------------- */
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $key, $value in $sprite-sizes {
            .sprite#{$infix}-#{$key} {
                width: $value;
                height: $value;
            }
        }
    }
}

.sprite-checkmark-circle {
    position: absolute;
    top: -1rem;
    right: -0.7rem;
}

.sprite-user {
    width: 21px;
    height: 21px;
}

.sprite-offer {
    margin-bottom: 0.1rem;
}
