.passwordStrengthBar {
    height: 0.2rem;
    display: flex;

    .passwordStrengthBlib {
        height: 0.25rem;
        background-color: $neutral-a10;
        transition: transition-duration(1);
    }

    &.ps1 {
        .passwordStrengthBlib:nth-child(1) {
            background-color: $red;
        }
    }

    &.ps2 {
        .passwordStrengthBlib:nth-child(1),
        .passwordStrengthBlib:nth-child(2) {
            background-color: $orange;
        }
    }

    &.ps3 {
        .passwordStrengthBlib:nth-child(1),
        .passwordStrengthBlib:nth-child(2),
        .passwordStrengthBlib:nth-child(3) {
            background-color: $promo;
        }
    }
}

.textScore-ps0 {
    color: $neutral-a10;
}

.textScore-ps1 {
    color: $red;
}

.textScore-ps2 {
    color: $orange;
}

.textScore-ps3 {
    color: $promo;
}
