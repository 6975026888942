/* Grid
----------------------------------------------------------- */
$grid-gutter-width: 32px; // Then can do mx-n4 to stick to the edge of the screen on smartphone.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1440px,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px,
);

/**
 * Theme colors
 *
 * Usage:
 * - SCSS: $colorname
 * - Html class:
 *      - Foreground color: .text-<key>
 *      - Background color: .bg-<key>
 *      - Border color: .border-<key>
 ----------------------------------------------------------- */
$primary: #004e4e;
$primary-dark: #003435;
$primary-x-dark: #001a1a;
$secondary: #c3b7a9;
$secondary-a30: rgba($secondary, 0.3);
$purple: #7c72ba;
$purple-light: #c8d2f0;
$purple-x-light: #e5e3f1;
$purple-a50: rgba($purple, 0.5);
$purple-a25: rgba($purple, 0.25);
$purple-a10: rgba($purple, 0.1);
$purple-a5: rgba($purple, 0.05);
$purple-dark: #766cb7;
$purple-x-dark: #413b78;
$neutral: #323a32;
$neutral-a80: rgba($neutral, 0.8);
$neutral-a70: rgba($neutral, 0.7);
$neutral-a10: rgba($neutral, 0.1);
$neutral-a5: rgba($neutral, 0.05);
$neutral-white: #ffffff;
$neutral-white-a80: rgba($neutral-white, 0.8);
$neutral-white-a60: rgba($neutral-white, 0.6);
$neutral-white-a5: rgba($neutral-white, 0.05);
$neutral-green: #c1d3d1;
$off-white-dark: #f1efe5;
$off-white: #f8f7f2;
$promo: #009999;
$promo-a5: rgba($promo, 0.05);
$camel: #ab967a;
$red: #ba1515;
$red-a20: rgba($red, 0.2);

// Not in figma colors yet but used in components
$orange: #da640e;
$transparent: transparent;
$danger: $red;
$success: $promo;

$theme-colors: (
    'primary': $primary,
    'primary-dark': $primary-dark,
    'primary-x-dark': $primary-x-dark,
    'secondary': $secondary,
    'secondary-a30': $secondary-a30,
    'purple': $purple,
    'purple-light': $purple-light,
    'purple-x-light': $purple-x-light,
    'purple-a50': $purple-a50,
    'purple-a25': $purple-a25,
    'purple-a10': $purple-a10,
    'purple-a5': $purple-a5,
    'purple-dark': $purple-dark,
    'purple-x-dark': $purple-x-dark,
    'neutral': $neutral,
    'neutral-a80': $neutral-a80,
    'neutral-a70': $neutral-a70,
    'neutral-a10': $neutral-a10,
    'neutral-a5': $neutral-a5,
    'neutral-white': $neutral-white,
    'neutral-white-a80': $neutral-white-a80,
    'neutral-white-a60': $neutral-white-a60,
    'neutral-white-a5': $neutral-white-a5,
    'neutral-green': $neutral-green,
    'off-white': $off-white,
    'off-white-dark': $off-white-dark,
    'promo': $promo,
    'promo-a5': $promo-a5,
    'camel': $camel,
    'red': $red,
    'red-a20': $red-a20,
    'orange': $orange,
    'transparent': $transparent,
);

$grays: (
    100: #f6f6f6,
    200: #f1efe5,
    300: #cbcbca,
    300a20: rgba(#cbcbca, 0.2),
    300a50: rgba(#cbcbca, 0.5),
    400: #dbdcdd,
    500: #888b85,
    600: #707570,
    700: #323a32,
    700a75: rgba(#323a32, 0.75),
);

/* Body
----------------------------------------------------------- */
$body-bg: $off-white;
$body-color: gray(700);

$body-selection-color: $primary;
$body-selection-background-color: $promo-a5;
$body-selection-text-shadow: none;

/* Borders
----------------------------------------------------------- */
$border-positions: top, left, bottom, right;
$border-widths: 0, 1, 2;

$border-radiuses: (
    none: 0,
    xs: 2px,
    sm: 4px,
    md: 8px,
    lg: 16px,
    xl: 32px,
    50: 50%,
    max: 100%,
);

$border-radius: border-radius(md);
$border-radius-sm: border-radius(sm);
$border-radius-lg: border-radius(lg);

/* Fonts
----------------------------------------------------------- */
$font-family-sans-serif: 'GT-Haptik-Goed-Regular', Tahoma, sans-serif;
$font-family-bold: 'GT-Haptik-Goed-Bold', Tahoma, sans-serif;
$headings-font-family: 'GT-Haptik-Goed-Bold', Tahoma, sans-serif;

$headings-font-weight: 400;

$font-sizes: 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 40, 48, 54, 56;

$font-size-base: font-size(16);
$lead-font-size: font-size(16);
$lead-font-weight: 400;

$h1-font-size: font-size(48);
$h2-font-size: font-size(32);
$h3-font-size: font-size(28);
$h4-font-size: font-size(24);
$h5-font-size: font-size(20);
$h6-font-size: font-size(18);

$line-height-base: 1.6;

$enable-responsive-font-sizes: false; // Headings have fixed font sizes on mobile devices (see _typography.scss).

/* Spacing
----------------------------------------------------------- */
$spacer: 1rem;
$spacers: ();
@for $i from -150 through 150 {
    $spacers: map-merge(
        $spacers,
        (
            $i: (
                spacer($i),
            ),
        )
    );
}

/* Sprites
----------------------------------------------------------- */
$sprite-sizes: (
    xxxs: 6px,
    xxs: 8px,
    xs: 13px,
    sm: 16px,
    md: 22.5px,
    lg: 28px,
    xl: 44px,
);

/* Transitions
----------------------------------------------------------- */
$transition-durations: (
    1: 0.2s ease-out,
    2: 0.7s ease-out,
    3: 1.5s ease-out,
    4: 2.75s ease-out,
    5: 4s ease-out,
    6: 6s ease-out,
);

/* Max widths
----------------------------------------------------------- */
$max-widths: (
    xxxs: rem(48),
    xs: 20rem,
    sm: 28rem,
    md: 34rem,
    lg: 56rem,
    xl: 60rem,
    xxl: 70rem,
);

/* Logo
----------------------------------------------------------- */
$logo-vertical-ratio: 2.3923;
$logo-vertical-height: $line-height-base * 1.6rem;
$logo-vertical-width: $logo-vertical-height * $logo-vertical-ratio;
$logo-vertical-height-small: $line-height-base * 1.2rem;
$logo-vertical-width-small: $logo-vertical-height-small * $logo-vertical-ratio;

/* Anchor
----------------------------------------------------------- */
$link-color: $purple-dark;
$link-hover-color: $purple-x-dark;
$link-hover-decoration: none;

/* Navigation
----------------------------------------------------------- */
$navigation-main-breakpoint: 'lg';

/* Breadcrumb
----------------------------------------------------------- */
$breadcrumb-padding-x: 0;
$breadcrumb-font-size: font-size(16);
$breadcrumb-bg: transparent;
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC42NSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjE0NjQ1IDQuMTQ2NDVDNS4zNDE3MSAzLjk1MTE4IDUuNjU4MjkgMy45NTExOCA1Ljg1MzU1IDQuMTQ2NDVMOC44NTM1NSA3LjE0NjQ1QzkuMDQ4ODIgNy4zNDE3MSA5LjA0ODgyIDcuNjU4MjkgOC44NTM1NSA3Ljg1MzU1TDUuODUzNTUgMTAuODUzNkM1LjY1ODI5IDExLjA0ODggNS4zNDE3MSAxMS4wNDg4IDUuMTQ2NDUgMTAuODUzNkM0Ljk1MTE4IDEwLjY1ODMgNC45NTExOCAxMC4zNDE3IDUuMTQ2NDUgMTAuMTQ2NEw3Ljc5Mjg5IDcuNUw1LjE0NjQ1IDQuODUzNTVDNC45NTExOCA0LjY1ODI5IDQuOTUxMTggNC4zNDE3MSA1LjE0NjQ1IDQuMTQ2NDVaIiBmaWxsPSIjODg4Qjg1Ii8+Cjwvc3ZnPgo=');
$breadcrumb-divider-white: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC42NSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjE0NjQ1IDQuMTQ2NDVDNS4zNDE3MSAzLjk1MTE4IDUuNjU4MjkgMy45NTExOCA1Ljg1MzU1IDQuMTQ2NDVMOC44NTM1NSA3LjE0NjQ1QzkuMDQ4ODIgNy4zNDE3MSA5LjA0ODgyIDcuNjU4MjkgOC44NTM1NSA3Ljg1MzU1TDUuODUzNTUgMTAuODUzNkM1LjY1ODI5IDExLjA0ODggNS4zNDE3MSAxMS4wNDg4IDUuMTQ2NDUgMTAuODUzNkM0Ljk1MTE4IDEwLjY1ODMgNC45NTExOCAxMC4zNDE3IDUuMTQ2NDUgMTAuMTQ2NEw3Ljc5Mjg5IDcuNUw1LjE0NjQ1IDQuODUzNTVDNC45NTExOCA0LjY1ODI5IDQuOTUxMTggNC4zNDE3MSA1LjE0NjQ1IDQuMTQ2NDVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
$breadcrumb-divider-mobile: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03Ljg1MzU1IDkuMzUzNTVDNy42NTgyOSA5LjU0ODgyIDcuMzQxNzEgOS41NDg4MiA3LjE0NjQ1IDkuMzUzNTVMNC4xNDY0NSA2LjM1MzU1QzMuOTUxMTggNi4xNTgyOSAzLjk1MTE4IDUuODQxNzEgNC4xNDY0NSA1LjY0NjQ1TDcuMTQ2NDUgMi42NDY0NUM3LjM0MTcxIDIuNDUxMTggNy42NTgyOSAyLjQ1MTE4IDcuODUzNTUgMi42NDY0NUM4LjA0ODgxIDIuODQxNzEgOC4wNDg4MSAzLjE1ODI5IDcuODUzNTUgMy4zNTM1NUw1LjIwNzExIDZMNy44NTM1NSA4LjY0NjQ1QzguMDQ4ODIgOC44NDE3MSA4LjA0ODgyIDkuMTU4MjkgNy44NTM1NSA5LjM1MzU1WiIgZmlsbD0iIzcwNjdBOSIvPgo8L3N2Zz4K');
$breadcrumb-divider-mobile-white: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC42NSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04Ljg1MzU1IDQuMTQ2NDVDOC42NTgyOSAzLjk1MTE4IDguMzQxNzEgMy45NTExOCA4LjE0NjQ1IDQuMTQ2NDVMNS4xNDY0NSA3LjE0NjQ1QzQuOTUxMTggNy4zNDE3MSA0Ljk1MTE4IDcuNjU4MjkgNS4xNDY0NSA3Ljg1MzU1TDguMTQ2NDUgMTAuODUzNkM4LjM0MTcxIDExLjA0ODggOC42NTgyOSAxMS4wNDg4IDguODUzNTUgMTAuODUzNkM5LjA0ODgyIDEwLjY1ODMgOS4wNDg4MiAxMC4zNDE3IDguODUzNTUgMTAuMTQ2NEw2LjIwNzExIDcuNUw4Ljg1MzU1IDQuODUzNTVDOS4wNDg4MiA0LjY1ODI5IDkuMDQ4ODIgNC4zNDE3MSA4Ljg1MzU1IDQuMTQ2NDVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
$breadcrumb-active-color: gray(500);
$breadcrumb-item-padding: 0.2rem;

/* Form
----------------------------------------------------------- */
$btn-font-size-lg: $font-size-base;
$btn-padding-y: spacer(2);
$btn-padding-x: spacer(6);
$btn-padding-y-sm: spacer(2);
$btn-padding-x-sm: 1.125rem;
$btn-padding-y-lg: spacer(5);
$btn-padding-x-lg: spacer(10);

$btn-font-family: $font-family-bold;
$btn-line-height-sm: 1.5;
$btn-border-radius: $line-height-base + ($btn-padding-y * 2);
$btn-border-radius-sm: 3.5rem;
$btn-border-radius-lg: $line-height-base + ($btn-padding-y-lg * 2);
$btn-font-size-sm: font-size(14);

$input-padding-y: spacer(2);
$input-padding-x: spacer(3);
$input-padding-y-sm: $btn-padding-y-sm;
$input-padding-x-sm: $btn-padding-x-sm;
$input-font-family: $font-family-bold;
$input-border-radius: border-radius('xs');
$input-border-color: rgba(gray(300), 0.5);
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-border-color: $promo;
$input-placeholder-color: gray(300);

$form-feedback-font-size: font-size(12);
$enable-validation-icons: false;

$custom-checkbox-indicator-border-radius: border-radius(xs);
$custom-control-indicator-checked-bg: $purple;
$custom-control-indicator-active-bg: $purple-light;

$custom-select-indicator: url('/assets/images/svg/arrow-down.svg');
$custom-select-indicator-padding: spacer(8);
$custom-select-bg-size: spacer(6);
$custom-select-padding-y: spacer(1);
$custom-select-height: auto;

/* Cards
----------------------------------------------------------- */
$card-border-width: 0;
$card-border-radius: border-radius('md');

/* Carousel
----------------------------------------------------------- */
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 1;
$carousel-transition-duration: 0.3s;
$carousel-indicator-width: 8px;
$carousel-indicator-height: 8px;
$carousel-indicator-active-bg: gray(600);

/* Pagination
----------------------------------------------------------- */
$pagination-focus-outline: auto;
$pagination-border-width: 0;
$pagination-bg: none;
$pagination-hover-bg: none;
$pagination-active-bg: $neutral-white;

$box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.25);

/* List group
----------------------------------------------------------- */
$list-group-color: $body-color;
$list-group-bg: transparent;
$list-group-border-width: 0;
$list-group-active-color: $body-color;
$list-group-action-active-bg: transparent;
$list-group-active-bg: transparent;
$list-group-hover-bg: transparent;
$list-group-border-radius: border-radius(none);
$list-group-active-border-color: rgba(gray(300), 0.5);

/* Overflows
----------------------------------------------------------- */
$overflows: auto, hidden, visible;

/* Tabs
----------------------------------------------------------- */
$nav-tabs-link-active-bg: $neutral-white;
$nav-tabs-link-active-border-color: $neutral-white;
$nav-tabs-border-color: gray(300a20);

/* Alerts
----------------------------------------------------------- */
$alert-padding-y: 0.5rem;
$alert-padding-x: 0.75rem;
$alert-border-radius: border-radius('sm');

/* Modals
----------------------------------------------------------- */
$modal-content-bg: $neutral-white;

$embed-responsive-aspect-ratios: ((21 9), (16 9), (4 3), (3 2), (2 1), (1 1));
