/* Components and children
----------------------------------------------------------- */
.filmstrip {
    @include media-breakpoint-down('md') {
        overflow-x: scroll;
        overflow-y: auto;
        margin: 0 calc(($grid-gutter-width / 2) * -1);
        transform: translateZ(0);
        -webkit-overflow-scrolling: touch;
        -ms-scroll-chaining: chained;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        transform-style: preserve-3d;
    }
}

.filmstrip-content {
    @include media-breakpoint-down('md') {
        display: flex;
        padding: 0 0 0 calc($grid-gutter-width / 2);
        margin: 0 1rem 0 0;
        overflow-x: visible;
        transform: translateX(0);

        &:after {
            content: '';
            display: table;
            clear: both;
            flex-shrink: 0;
            width: calc($grid-gutter-width / 2);
            height: calc($grid-gutter-width / 2);
            scroll-snap-align: start;
        }

        > * {
            scroll-snap-align: start;
        }
    }
}

/* Modifiers
----------------------------------------------------------- */
