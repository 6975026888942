/* Components and children
----------------------------------------------------------- */
.search-bar {
    input {
        outline: none;
    }
}

.search-results {
    &-desktop {
        top: calc(100% + 0.5rem);
        z-index: 2000;
    }

    &-mobile,
    &-desktop {
        > * {
            transition: all 0.3s;

            &:hover {
                transition: all 0.3s;
                background-color: $purple-a5;
            }

            + * {
                border-top: 1px solid $neutral-a10;
            }
        }
    }
}

.search-bar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 0;
    width: spacer(8);
    height: spacer(8);
    border-radius: 50%;
    border: 0;
    box-shadow: none;
    color: $neutral-white;
    background: $purple;

    &:focus {
        outline: none;
    }
}

.search-bar-panel {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
    background: $neutral-white;
    border-radius: spacer(1);
}

.search-bar-panel-content {
    background-color: $neutral-white;

    &:not(:last-child) {
        border-bottom: 1px solid $gray-200;
    }

    a.search-bar-panel-content-item {
        display: flex;
        border-bottom: 0;
        color: $gray-700;
        font-family: $font-family-sans-serif;

        &:hover {
            color: $gray-900;
        }

        &:not(:first-child) {
            padding-top: spacer(2);
        }

        &:not(:last-child) {
            border-bottom: 1px solid $gray-200;
            padding-bottom: spacer(2);
        }
    }
}

.search-bar-panel-content-item-image {
    background: $off-white;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 5px;
    margin-right: spacer(3);
    position: relative;
    overflow: hidden;
}

/* Modifiers
----------------------------------------------------------- */
