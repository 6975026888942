@import '~swiper/css';
@import '~swiper/css/navigation';
@import '~swiper/css/scrollbar';
@import '~swiper/css/keyboard';

.swiper {
    position: initial;
}

.swiper-holder {
    position: relative;
}

// override
.swiper-track {
    display: flex;
}

// override
.swiper-slide {
    float: none;
    height: auto;
}

// override
.swiper-initialized {
    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// override
.swiper-button-prev,
.swiper-button-next {
    transform: none;
    transition: transition-duration(1);
    color: $purple !important;
    z-index: 200;

    &:hover {
        color: $purple;
    }

    @include media-breakpoint-up('lg') {
        top: auto;
        bottom: 0px;
        height: spacer(18);
    }
}

// override
.swiper-button-prev {
    &:after {
        content: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.4422 7.05849C21.9629 7.57919 21.9629 8.42341 21.4422 8.94411L14.385 16.0013L21.4422 23.0585C21.9629 23.5792 21.9629 24.4234 21.4422 24.9441C20.9215 25.4648 20.0772 25.4648 19.5565 24.9441L11.5565 16.9441C11.0358 16.4234 11.0358 15.5792 11.5565 15.0585L19.5565 7.05849C20.0772 6.53779 20.9215 6.53779 21.4422 7.05849Z" fill="%237c72ba"/></svg>') !important;
    }

    &:hover {
        transform: translateX(-3px);
    }
}

// override
.swiper-button-next {
    &:after {
        content: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.21967 3.96967C6.51256 3.67678 6.98744 3.67678 7.28033 3.96967L11.7803 8.46967C12.0732 8.76256 12.0732 9.23744 11.7803 9.53033L7.28033 14.0303C6.98744 14.3232 6.51256 14.3232 6.21967 14.0303C5.92677 13.7374 5.92678 13.2626 6.21967 12.9697L10.1893 9L6.21967 5.03033C5.92678 4.73744 5.92678 4.26256 6.21967 3.96967Z" fill="%237c72ba"/></svg>') !important;
    }

    &:hover {
        transform: translateX(3px);
    }
}

// override
.swiper-disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.3;
}

/* Modifiers
----------------------------------------------------------- */
.swiper-holder-light {
    padding: spacer(6);
    background-color: $neutral-white;
    // border: 1px solid $neutral-a10;
    border-radius: border-radius('lg');
}

.swiper-holder-has-thumbs {
    @include media-breakpoint-up('lg') {
        margin-bottom: spacer(2);
        padding-top: spacer(16);
        padding-bottom: spacer(20);
    }

    .swiper-pagination {
        display: none;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 100;
        bottom: 0px;
        left: 0px;

        @include media-breakpoint-up('lg') {
            display: flex;
        }
    }

    .swiper-pagination-bullet-active {
        border: 1px solid $purple;
    }

    @include media-breakpoint-up('lg') {
        padding-bottom: spacer(28);
        margin-bottom: spacer(20);

        &:after {
            content: '';
            position: absolute;
            z-index: 0;
            bottom: -8px;
            left: -1px;
            right: -1px;
            display: block;
            height: rem(115);
            background-image: linear-gradient(180deg, rgba($body-bg, 0) 0%, rgba($body-bg, 1) 80%);
        }
    }
}
