/* Components and children
----------------------------------------------------------- */
.form-text {
    color: gray(600);
    @include font-size(12);

    @include media-breakpoint-up('md') {
        @include font-size(14);
    }
}

// Override
.custom-select {
    width: auto;
}

// Override
.custom-control-label {
    line-height: 1.25;
}

// Override
.custom-switch {
    .custom-control-label {
        &::before {
            background-color: $secondary-a30;
            border-color: $secondary-a30;
        }

        &::after {
            background-color: $white;
            box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &::before {
            background-color: $purple;
            border-color: $purple;
        }
    }

    .custom-control-input.promo:checked ~ .custom-control-label {
        &::before {
            background-color: $promo;
            border-color: $promo;
        }
    }
}

// Override
.custom-checkbox {
    .custom-control-label::before,
    .custom-control-label::after {
        top: 0.2rem;

        @include media-breakpoint-up('md') {
            top: 0.3rem;
        }
    }
}

/* Modifiers
----------------------------------------------------------- */
.custom-radio-btn {
    margin-top: 3px;
    width: spacer(4);
    height: spacer(4);
    border: 1px solid $neutral-a70;
    border-radius: 100%;
    background: $neutral-white;
    &.active {
        transition: all 0.3s ease-in-out;
        border: 1px solid $purple;
        display: flex;
        align-items: center;
        justify-content: center;

        * {
            width: spacer(2);
            height: spacer(2);
            background-color: $purple;
            border-radius: 100%;
        }
    }
}

// Unmask password toggle
.form-control-password-toggle {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    box-shadow: none !important;
}
