/* Components and children
----------------------------------------------------------- */
.modal-viewport {
    margin: 0;
    max-width: none;

    .modal-header {
        border-radius: 0;
    }

    .modal-content {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
    }

    @supports (-webkit-touch-callout: none) {
        .modal-content {
            height: -webkit-fill-available; // Fix Safari iOS 100vh bug
        }
    }

    .modal-body {
        overflow-y: auto;
        flex-grow: 1;
        background-color: $body-bg;

        // Magic scroll shadow
        background-image: linear-gradient(to bottom, $body-bg, rgba($body-bg, 0)),
            linear-gradient(to top, $body-bg, rgba($body-bg, 0)),
            linear-gradient(to bottom, darken($body-bg, 12%), rgba($body-bg, 0)),
            linear-gradient(to top, darken($body-bg, 12%), rgba($body-bg, 0));

        background-position: 0 0, 0 100%, 0 0, 0 100%;
        background-repeat: no-repeat;
        background-size: 100% 5em, 100% 5em, 100% 1em, 100% 1em;
        background-attachment: local, local, scroll, scroll;
    }
}

.modal-content {
    overflow: hidden;
}

/* Modifiers
----------------------------------------------------------- */

.modal-viewport-bodybg {
    .modal-content {
        background-color: $body-bg;

        &:before {
            content: '';
            position: absolute;
            z-index: 0;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 545px;
            background: linear-gradient(180deg, rgba(gray(200), 1) 150px, rgba(gray(200), 0) 100%);
        }
    }

    .modal-header {
        z-index: 1;
        background-color: transparent;
    }

    .modal-body {
        background-color: transparent;
    }
}

.modal-fullscreen-mobile {
    .modal-dialog {
        @include media-breakpoint-down(xs) {
            margin: 0;
        }

        .modal-content {
            background-color: $off-white;
            @include media-breakpoint-down(xs) {
                border-radius: border-radius(none);
                min-height: 100vh;
            }
        }
    }
}

.search-store-modal {
    .stores-list {
        @include media-breakpoint-up(sm) {
            height: 50vh;
            overflow-y: auto;
        }
    }
}
