/* Components and children
----------------------------------------------------------- */

/* Modifiers
----------------------------------------------------------- */
.btn-tertiary {
    color: $neutral-white;
    background-color: $purple;
    border-color: $purple;
    font-family: $font-family-bold;

    transition: transition-duration(1);

    &:hover,
    &:active,
    &:focus {
        color: $neutral-white;
        background-color: $purple-dark;
    }
}

.btn-tertiary-outline {
    color: $purple;
    background-color: $neutral-white;
    border-color: $purple;
    font-family: $font-family-bold;

    transition: transition-duration(1);

    &:hover,
    &:active,
    &:focus {
        color: $white;
        background-color: $purple;
        border-color: $purple;
    }
}

.btn-unstyled {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.btn-no-caret {
    &:after {
        display: none;
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .btn#{$infix}-block {
            width: 100%;
            display: block;
        }

        .btn#{$infix}-auto {
            width: auto;
            display: inline-block;
        }
    }
}
