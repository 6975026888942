/* Components and children
----------------------------------------------------------- */
.navigation-mobile {
    // position: fixed;
    // top: -1rem;
    // left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background: $primary;
    transform-origin: top left;
    transition: all 0.2s ease;

    &.shown {
        opacity: 100%;
        z-index: 10;
        transform: scaleY(1);
    }

    &.hidden {
        opacity: 0%;
        z-index: -1;
        transform: scaleY(0.85);
    }
}

/* Modifiers
----------------------------------------------------------- */
.navigation-mobile-open,
.navigation-mobile-close {
    color: $neutral-white;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    outline: none;
    box-shadow: none;
    background: transparent;
}

.navigation-mobile-open {
    border: 1px solid $primary-dark;
    border-radius: 50%;
}

.navigation-mobile-close {
    position: absolute;
    right: 1rem;
    border: none;
}
