@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $position in $positions {
            .position#{$infix}-#{$position} {
                position: $position !important;
            }
        }
    }
}

@for $i from 1 through 10 {
    .z-index-#{$i} {
        z-index: $i;
    }
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.bottom-0 {
    bottom: 0;
}

.right-0 {
    right: 0;
}
