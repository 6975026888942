html,
body {
    position: relative;

    // Offset the scroll position, due to the sticky header
    scroll-padding-top: 165px;

    // Make font rendering as equal as possible across different browsers
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 545px;
        background: linear-gradient(180deg, rgba(gray(200), 1) 150px, rgba(gray(200), 0) 100%);
    }
}

main {
    min-height: calc(100vh - 70px - 178px);
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 200dpi),
    only screen and (min-resolution: 1.25dppx) {
    // @TODO: Find out why this fails.
    //-webkit-font-smoothing: subpixel-antialiased;
}

::-moz-selection {
    background-color: $body-selection-background-color;
    color: $body-selection-color;
    text-shadow: $body-selection-text-shadow;
}

::selection {
    background-color: $body-selection-background-color;
    color: $body-selection-color;
    text-shadow: $body-selection-text-shadow;
}
