/* Components and children
----------------------------------------------------------- */
.cart-indicator {
    .indicator {
        top: -20%;
        right: -20%;
    }
}

.cart-pricing-summary {
    @include media-breakpoint-up(sm) {
        border-top-left-radius: border-radius(none) !important;
        border-top-right-radius: border-radius(none) !important;
    }
}

/* Modifiers
----------------------------------------------------------- */
