.progressbar-steps {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    z-index: 2;

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        text-transform: lowercase;
        font-size: font-size(14);
        text-align: center;
        background-color: $transparent;
        color: $neutral;
        padding: spacer(0);

        @include media-breakpoint-up(sm) {
            font-size: font-size(18);
        }

        .indicator {
            width: spacer(6);
            height: spacer(6);
            border: 3px solid gray(300);
            background: $neutral-white;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-bottom: spacer(1);

            .indicator-bg {
                width: spacer(3);
                height: spacer(3);
                background: gray(300);
                border-radius: 50%;
            }
        }

        &:not(:last-child) {
            border-right: 0px solid gray(300);

            .indicator {
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;

                    background: gray(300);
                    top: calc(0.75rem - 1px);
                    left: 50%;
                    z-index: -1;
                }
            }
        }

        &.active {
            font-family: $font-family-bold;
            color: $neutral;
            background-color: $transparent;
            .indicator {
                .indicator-bg {
                    background: $purple;
                }
            }
        }

        &.done {
            .indicator {
                border-color: $purple;
                background: $purple;
            }
        }
    }
}
