.subheader-modal {
    .modal-body {
        overflow-y: scroll;
    }

    @include media-breakpoint-up(sm) {
        .modal-content {
            height: 100vh;
            border-radius: 0 !important;
        }

        .modal-dialog {
            margin: 0 !important;
        }

        &.modal.fade .modal-dialog {
            transition: transform 0.3s ease-out;
            transform: translate(0, 0);
        }
    }
}
