.img-object-fit-contain {
    object-fit: contain;
}

.img-object-fit-cover {
    object-fit: cover;
}

.bg-img-size-cover {
    background-size: cover;
}

.bg-img-no-repeat {
    background-repeat: no-repeat;
}

.bg-img-position-center {
    background-position: center;
}
