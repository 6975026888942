@font-face {
    font-family: 'GT-Haptik-Goed-Regular';
    src: url('/assets/fonts/GT-Haptik-Goed-Regular.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/GT-Haptik-Goed-Regular.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/GT-Haptik-Goed-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT-Haptik-Goed-Bold';
    src: url('/assets/fonts/GT-Haptik-Goed-Bold.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/GT-Haptik-Goed-Bold.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/GT-Haptik-Goed-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT-Haptik-Goed-Light';
    src: url('/assets/fonts/GT-Haptik-Goed-Light.eot'); /* IE9 Compat Modes */
    src: url('/assets/fonts/GT-Haptik-Goed-Light.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/GT-Haptik-Goed-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
