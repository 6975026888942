@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .w#{$infix}-fit {
            width: fit-content !important;
        }

        @each $key, $value in $spacers {
            .ws#{$infix}-#{$key} {
                width: $value !important;
            }
            .hs#{$infix}-#{$key} {
                height: $value !important;
            }

            .mws#{$infix}-#{$key} {
                max-width: $value !important;
            }
            .mhs#{$infix}-#{$key} {
                max-height: $value !important;
            }
        }

        @each $prop, $abbrev in (width: w, height: h) {
            @each $size, $length in $sizes {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }
            }
        }
    }
}

.w-fit {
    width: fit-content !important;
}

.h-fit {
    height: fit-content !important;
}
