/* Components and children
----------------------------------------------------------- */

/* Modifiers
----------------------------------------------------------- */
.cm-price-md {
    font-size: font-size(16);

    @include media-breakpoint-up(md) {
        font-size: font-size(18);
    }
}

.cm-price-sm {
    font-size: font-size(14);

    @include media-breakpoint-up(md) {
        font-size: font-size(16);
    }
}
