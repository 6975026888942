/* Components and children
----------------------------------------------------------- */
.breadcrumb-wrapper {
    // @include media-breakpoint-up(md) {
    //     padding: spacer(4) 0;
    // }

    @include media-breakpoint-up(lg) {
        top: 85px;
    }

    @include media-breakpoint-up($navigation-main-breakpoint) {
        top: 105px;
    }
}

ol.breadcrumb {
    padding-top: spacer(4);
    padding-bottom: spacer(4);
    line-height: 1;

    @include media-breakpoint-up(lg) {
        padding-top: spacer(6);
        padding-bottom: spacer(6);
        border-bottom: 1px solid $purple-a10;
        border-radius: 0;
    }
}

.breadcrumb-item {
    display: flex;

    a {
        font-family: $font-family-sans-serif;
        color: gray(700);

        &:hover,
        &:active,
        &:focus {
            color: $purple;
        }

        border-bottom: none;
    }

    &.active a {
        color: $neutral-a70;
    }

    & + &:before {
        margin-top: 3px;
        padding-right: 0;
        content: $breadcrumb-divider;
    }
}

/* Modifiers
----------------------------------------------------------- */
.breadcrumb.breadcrumb-enable-mobile-fallback {
    .breadcrumb-item {
        // Only show last link in breadcrumb
        @include media-breakpoint-down(sm) {
            display: none;

            &:nth-last-child(2) {
                display: flex;

                a {
                    color: $purple;
                }

                &:before {
                    content: escape-svg($breadcrumb-divider-mobile);
                    display: block;
                    margin: 2px 0 0 -4px;
                    color: $purple;
                }
            }
        }
    }
}
