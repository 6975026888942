.blob-left {
    position: relative;

    &:before {
        content: '';
        display: none;
        position: absolute;
        top: -75px;
        left: 0;
        width: 66px;
        height: 562px;
        background-image: url('/assets/images/png/goed-header-shape-01.png');
        background-repeat: no-repeat;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.blob-right {
    position: relative;

    &:after {
        content: '';
        display: none;
        position: absolute;
        right: 0;
        top: 700px;
        width: 253px;
        height: 577px;
        background-image: url('/assets/images/png/goed-header-shape-02.png');
        pointer-events: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}
