.list-divider-dotted {
    height: 2px;
    background-image: url('/assets/images/svg/dotted-line-divider-horizontal.svg');
    background-repeat: repeat-x;
}

.list-divider-solid {
    border-bottom: 1px solid $purple-a25;
}

.list-divider-edge-to-edge {
    margin-left: calc(($grid-gutter-width / 2) * -1);
    margin-right: calc(($grid-gutter-width / 2) * -1);
}

.list-divider-textual {
    display: flex;
    align-items: center;
    color: gray(600);

    &:before,
    &:after {
        content: '';
        flex-grow: 1;
        display: block;
        border-bottom: 1px solid gray(300a50);
    }

    &:before {
        margin-right: spacer(5);
    }

    &:after {
        margin-left: spacer(5);
    }
}

.list-checkmarks {
    margin: 0;
    padding: 0;
    list-style-image: url('/assets/images/svg/checkmark.svg');
    padding-left: spacer(8);
    li {
        padding-left: spacer(2);
    }
}

.list-colored-dots {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        display: flex;
        margin-bottom: spacer(1);

        &:before {
            content: '';
            display: block;
            margin: spacer(3) spacer(2) 0 0;
            width: 4px;
            height: 4px;
            border-radius: border-radius('max');
            background-color: $promo;
        }
    }
}
