/* Components and children
----------------------------------------------------------- */
.text-toggle-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include media-breakpoint-up('md') {
        white-space: normal;
        text-overflow: unset;
        overflow: visible;
    }
}

.text-toggle-link-open {
    float: right;
    margin-left: spacer(2);

    @include media-breakpoint-up('md') {
        display: none;
    }
}

.text-toggle-link-close {
    display: none;
}

.text-toggle.is-open {
    .text-toggle-content {
        white-space: normal;
        text-overflow: unset;
        overflow: visible;
    }

    .text-toggle-link-open {
        display: none;
    }

    .text-toggle-link-close {
        display: inline;
    }
}

/* Modifiers
----------------------------------------------------------- */
