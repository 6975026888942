/* Product badge
----------------------------------------------------------- */
.product-badge {
    padding: rem(2) rem(8);
    border-radius: rem(4);
    width: fit-content;
    color: map-get($theme-colors, 'neutral-a70');
    background: map-get($theme-colors, 'neutral-a5');
    line-height: 1.5;
    font-size: rem(16);
    font-weight: 400;

    @include media-breakpoint-up('md') {
        font-size: rem(20);
    }
}
