::placeholder {
    font-family: $font-family-sans-serif;
}

label {
    margin-bottom: spacer(2);
    @include font-size(14);
    color: $neutral-a80;

    @include media-breakpoint-up('md') {
        @include font-size(16);
    }
}

textarea {
    resize: none;
    min-height: 100px;
}
