.pagination-btn {
    color: $purple;
    background: none;
    border: none;
    border: 2px solid $purple-a25;
    border-radius: 2px;
    padding: spacer(1);
    margin: 0 4px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    height: 32px;

    &.active {
        background: $neutral-white;
        min-width: 40px;
        height: 40px;
        border: none;
        color: $neutral;
        border-radius: 50%;
        font-family: $font-family-bold;
    }
}

.pagination-dots {
    padding: spacer(1);
    margin: 0 4px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    height: 32px;
    color: $neutral-a70;
}

.pagination-next,
.pagination-prev {
    border: none;
    background: none;
    color: $purple;
}
