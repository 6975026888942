h1,
.h1 {
    margin-bottom: 1.25rem;
    font-size: font-size(22);

    @include media-breakpoint-up('md') {
        font-size: font-size(26);
        margin-bottom: 2rem;
    }

    @include media-breakpoint-up('xl') {
        font-size: font-size(32);
    }
}

h2,
.h2 {
    margin-bottom: 1rem;
    font-size: font-size(20);

    @include media-breakpoint-up('md') {
        margin-bottom: 1.5rem;
        font-size: font-size(24);
    }

    @include media-breakpoint-up('xl') {
        font-size: font-size(28);
    }
}

h3,
.h3 {
    margin-bottom: spacer(6);
    color: $primary;
    font-size: font-size(18);

    @include media-breakpoint-up('md') {
        font-size: font-size(22);
    }

    @include media-breakpoint-up('lg') {
        font-size: font-size(24);
    }
}

h4,
.h4 {
    font-size: font-size(18);

    @include media-breakpoint-up('lg') {
        font-size: font-size(20);
    }
}

h5,
.h5 {
    margin-bottom: spacer(6);
    font-size: font-size(18);
}

h6,
.h6 {
    margin-bottom: spacer(4);
    font-size: font-size(16);
}

a,
.anchor {
    color: $link-color;
    word-break: break-word;
    font-family: $font-family-bold;
    border-bottom: 1px solid $purple-a25;

    &:hover {
        color: $link-hover-color;
        border-bottom-color: $purple-light;
        transition: border transition-duration(1);
    }

    &.active,
    &:focus {
        color: $purple-x-dark;
    }
}

strong,
.font-weight-bold {
    font-family: $font-family-bold;
    font-weight: 400 !important;
}

.word-break {
    word-break: break-word;
}
