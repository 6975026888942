/* Components and children
----------------------------------------------------------- */
.spinner {
    position: relative;
    text-indent: -9999rem;
    border-top: solid rgba(gray(400), 0.4);
    border-right: solid rgba(gray(400), 0.4);
    border-bottom: solid rgba(gray(400), 0.4);
    border-left: solid gray(400);

    transform: translateZ(0);
    animation: spinner 1.1s infinite linear;

    &,
    &:after {
        border-radius: 50%;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Modifiers
----------------------------------------------------------- */
.spinner-sm {
    &,
    &:after {
        width: 1rem;
        height: 1rem;
        border-width: 0.2rem;
    }
}

.spinner-md {
    &,
    &:after {
        width: 2rem;
        height: 2rem;
        border-width: 0.3rem;
    }
}

.spinner-lg {
    &,
    &:after {
        width: 3rem;
        height: 3rem;
        border-width: 0.4rem;
    }
}

.spinner-neutral-white {
    border-top: solid rgba($neutral-white, 0.4);
    border-right: solid rgba($neutral-white, 0.4);
    border-bottom: solid rgba($neutral-white, 0.4);
    border-left: solid $neutral-white;
}
